.Login-Page {
  text-align: center;
}

.Login-Container {
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Login-Title {
  color: #393939;
}
