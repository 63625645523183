.AppIndex-PlaygroundItem {
    background-color: #01082b;
    padding: 20px;
    width: 600px;
    height: 100px;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
}
